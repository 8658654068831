// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";
// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "./src/style.css";

// Highlighting for code blocks
import "prismjs/themes/prism.css";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={"6LfPVrEjAAAAAJyI3Pvi-mW2R2Xka9BJB8VWU9aO"}
      container={{
        // optional to render inside custom element
        element: "cxosync_recaptcha",
        parameters: {
          badge: 'bottomleft', // optional, default undefined
          // theme: 'dark', // optional, default undefined
        }
      }}
    >
      {element}
      <div id="cxosync_recaptcha" className={"cxosync_recaptcha"}></div>
    </GoogleReCaptchaProvider>
  );
};
